import Button, { ButtonProps } from "@material-ui/core/Button";
import CustomDialogWrapper from "../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper";
import { useCallback, useEffect, useMemo, useState } from "react";
import GenericObject from "../../../../../../typesAdditional/GenericObject";
import api from "../../../../../../common/utils/api";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from "@material-ui/core";
import SelectPeriodsDialog from "../AddPaymentDialog/components/SelectPeriodsDialog/SelectPeriodsDialog";
import CustomTextField from "../../../../../../common/components/CustomTextField/CustomTextField";
import WarningIcon from '@material-ui/icons/Warning';
import { useMessageDialog } from "../../../../../../common/hooks/useMessageDialog/useMessageDialog";
import snackbar from "../../../../../../common/utils/snackbar";
import { downloadBlob } from 'download.js';

interface Props {
    open: boolean;
    closeDialog: () => void;
    season: 'summer' | 'winter' | 'winter_facilities'
    refreshData: () => void;
}

export const MONTHS = ['gennaio', 'febbraio', 'marzo', 'aprile', 'maggio', 'giugno', 'luglio', 'agosto', 'settembre', 'ottobre', 'novembre', 'dicembre'];

const missingPersonalDataMap = {
    name: 'nome',
    surname: 'cognome',
    tax_code: 'codice fiscale',
    iban: 'IBAN',
    residence: 'residenza'
};

const BulkPaymentsDialog = ({ open, closeDialog, season, refreshData }: Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<GenericObject[]>([]);
    const [errroData, setErrorData] = useState<GenericObject[]>([]);
    const [simulationData, setSimulationData] = useState({
        totalTrainers: 0,
        totalAmount: 0.0,
        cbiTrainers: 0,
        cbiAmount: 0.0
    });

    const [isSelectPeriodsDialogOpen, setIsSelectPeriodsDialogOpen] = useState(false);
    const [selectedPeriods, setSelectedPeriods] = useState<string[]>([]);
    const [selectedPeriodDesc, selectPeriodDesc] = useState<GenericObject>({});

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [dataToSave, setDataToSave] = useState<GenericObject[]>([]);

    /**
     * 1 - selezione periodo e causale
     * 2 - tabella inserimento massivo
     * 3 - check fatture
     * 4 - check dati anagrafici
     * 5 - check superamento soglia 5k
     * 6 - check superamento soglia 15k
     * 7 - confirm (?)
     */
    const [step, setStep] = useState(1);

    const [sepaDescription, setSepaDescription] = useState('');
    const [defaultPeriodDesc, setDefaultPeriodDesc] = useState('');
    const [overridePeriodDesc, setOverridePeriodDesc] = useState('');

    const sortedSelectedPeriods = useMemo(() => {
        const mainArray = data[0]?.periods ?? [];

        return selectedPeriods.sort((a: any, b: any) => {
            return mainArray.findIndex((item: any) => item.id === a) - mainArray.findIndex((item: any) => item.id === b);
        })
    }, [data, selectedPeriods]);

    const selectedPeriodsDesc = useMemo(() => {
        for (const monthPeriod of (data[0]?.monthPeriods ?? [])) {
            if (monthPeriod.fullWeeks.every((w: any) => sortedSelectedPeriods.includes(w)) && sortedSelectedPeriods.every((w: any) => [...monthPeriod.fullWeeks, ...monthPeriod.partialWeeks].includes(w))) {
                return [monthPeriod.name];
            }
        }

        return sortedSelectedPeriods.map((p: any) => {
            return (data[0]?.periods ?? []).find((p2: any) => p2.id === p)?.name;
        });
    }, [sortedSelectedPeriods, data]);

    const selectedPeriodsDescShortName = useMemo(() => {
        for (const monthPeriod of (data[0]?.monthPeriods ?? [])) {
            if (monthPeriod.fullWeeks.every((w: any) => sortedSelectedPeriods.includes(w)) && sortedSelectedPeriods.every((w: any) => [...monthPeriod.fullWeeks, ...monthPeriod.partialWeeks].includes(w))) {
                return [monthPeriod.name];
            }
        }

        return sortedSelectedPeriods.map((p: any) => {
            return (data[0]?.periods ?? []).find((p2: any) => p2.id === p)?.shortName;
        });
    }, [sortedSelectedPeriods, data]);

    const selectPeriod = useCallback((id: string, selected: boolean) => {
        setSelectedPeriods((currPeriods: any) => {
            const filteredPeriods = currPeriods.filter((x: string) => x !== id);

            if (selected) {
                return [...filteredPeriods, id];
            } else {
                return filteredPeriods;
            }
        });
    }, []);

    const getDataForTrainer = useCallback((trainerId: string, key: string) => {
        const trainerData = dataToSave.find((d: any) => d.trainerId === trainerId) ?? { trainerId };
        return trainerData[key] ?? null;
    }, [dataToSave]);

    const setDataForTrainer = useCallback((trainerId: string, key: string, value: any) => {
        setDataToSave((oldData: GenericObject[]) => {
            const trainerData = oldData.find((d: any) => d.trainerId === trainerId) ?? { trainerId };
            const updatedData = { ...trainerData, [key]: value };
            return [...oldData.filter((d: any) => d.trainerId !== trainerId), updatedData];
        });

        const trainerData = data.find((t: any) => t.trainer.id === trainerId) ?? {};

        if (key === 'invoiceNumber' && !!value.trim()) {
            const newSepaDescription = ('[COGNOME] [NOME] SALDO FATTURA ' + value.trim()).replaceAll('[NOME]', trainerData.trainer.firstName.toUpperCase()).replaceAll('[COGNOME]', trainerData.trainer.lastName.toUpperCase());
            setDataForTrainer(trainerId, 'sepaDescription', newSepaDescription);
        } else if (key === 'invoiceNumber' && !value.trim()) {
            setDataForTrainer(trainerId, 'sepaDescription', sepaDescription.replaceAll('[NOME]', trainerData.trainer.firstName.toUpperCase()).replaceAll('[COGNOME]', trainerData.trainer.lastName.toUpperCase()));
        }
    }, [data, sepaDescription]);

    const base64toBlob = useCallback((dataURI: string) => {
        // convert a base64 string to a blob object
        const byteString = atob(dataURI);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab]);
    }, []);

    const nextStep = useCallback((overrideStep?: number) => {
        let realCurrentStep = overrideStep ?? step;

        if (realCurrentStep === 1) {
            for (const trainerData of data) {
                const toPay = (trainerData?.periods ?? []).filter((p: any) => sortedSelectedPeriods.includes(p.id)).reduce((prev: any, curr: any) => {
                    return prev + curr.toPay;
                }, 0.0);

                const globalToPay = Math.max(0, parseFloat(trainerData?.paymentStatus?.totalPayouts ?? 0) - parseFloat(trainerData?.paymentStatus?.paidPayouts ?? 0));

                setDataForTrainer(trainerData.trainer.id, 'paid', Math.min(Math.max(toPay, 0), globalToPay).toFixed(2));
                setDataForTrainer(trainerData.trainer.id, 'esTaxes', '0.00');
                setDataForTrainer(trainerData.trainer.id, 'trainerTaxes', '0.00');
                setDataForTrainer(trainerData.trainer.id, 'sepaDescription', sepaDescription.replaceAll('[NOME]', trainerData.trainer.firstName.toUpperCase()).replaceAll('[COGNOME]', trainerData.trainer.lastName.toUpperCase()));
            }

            setStep(2);
        } else if (realCurrentStep === 2) {
            setIsLoading(true);

            api.request('/admin/trainer_payments/' + season + '/bulk_payment_checks/3', 'POST', { data: dataToSave }).then((res: any) => {
                if (res.length === 0) {
                    setIsLoading(false);
                    nextStep(3);
                } else {
                    setErrorData(res);
                    setIsLoading(false);
                    setStep(3);
                }
            });
        } else if (realCurrentStep === 3) {
            setIsLoading(true);

            api.request('/admin/trainer_payments/' + season + '/bulk_payment_checks/4', 'POST', { data: dataToSave }).then((res: any) => {
                if (res.length === 0) {
                    setIsLoading(false);
                    nextStep(4);
                } else {
                    setErrorData(res);
                    setIsLoading(false);
                    setStep(4);
                }
            });
        } else if (realCurrentStep === 4) {
            setIsLoading(true);

            api.request('/admin/trainer_payments/' + season + '/bulk_payment_checks/5', 'POST', { data: dataToSave, selectedPeriods: sortedSelectedPeriods }).then((res: any) => {
                if (res.length === 0) {
                    setIsLoading(false);
                    nextStep(5);
                } else {
                    setErrorData(res);
                    setIsLoading(false);
                    setStep(5);
                }
            });
        } else if (realCurrentStep === 5) {
            setIsLoading(true);

            api.request('/admin/trainer_payments/' + season + '/bulk_payment_checks/6', 'POST', { data: dataToSave, selectedPeriods: sortedSelectedPeriods }).then((res: any) => {
                if (res.length === 0) {
                    setIsLoading(false);
                    nextStep(6);
                } else {
                    setErrorData(res);
                    setIsLoading(false);
                    setStep(6);
                }
            });
        } else if (realCurrentStep === 6) {
            setIsLoading(true);

            api.request('/admin/trainer_payments/' + season + '/bulk_payments/simulate', 'POST', { data: dataToSave, selectedPeriods: sortedSelectedPeriods, selectedPeriodDesc: { ...selectedPeriodDesc, overrideDesc: overridePeriodDesc === defaultPeriodDesc ? null : overridePeriodDesc } }).then((res: any) => {
                setIsLoading(false);
                setSimulationData(res);
                setStep(7);
            });
        } else if (realCurrentStep === 7) {
            setIsLoading(true);

            api.request('/admin/trainer_payments/' + season + '/bulk_payments/save', 'POST', { data: dataToSave, selectedPeriods: sortedSelectedPeriods, selectedPeriodDesc: { ...selectedPeriodDesc, overrideDesc: overridePeriodDesc === defaultPeriodDesc ? null : overridePeriodDesc } }).then((res: any) => {
                setIsLoading(false);
                snackbar.success('Compensi salvati con successo!');
                const blob = base64toBlob(res.cbiContent);
                downloadBlob(res.cbiFilename, blob);
                closeDialog();
                refreshData();
            });
        }
    }, [refreshData, step, data, sortedSelectedPeriods, selectedPeriodDesc, sepaDescription, dataToSave, season, setDataForTrainer, base64toBlob, closeDialog, overridePeriodDesc, defaultPeriodDesc]);

    const zeroIfNan = useCallback((number: any) => {
        if (isNaN(number)) return 0;
        return number;
    }, []);

    const handleCloseDialog = useCallback(() => {
        if (step === 1) {
            closeDialog();
        } else if (step === 2) {
            showMessageDialog({
                title: 'Interrompi inserimento',
                message: (
                    <>
                        <p style={{ margin: '0px' }}>
                            Sei sicuro di volere interrompere la procedura di inserimento? I dati non verranno salvati.
                        </p>
                    </>
                ),
                actions: [
                    {
                        text: 'No',
                        action: () => {
                            closeMessageDialog();
                        }
                    },
                    {
                        text: 'Sì',
                        action: () => {
                            closeMessageDialog();
                            closeDialog();
                        }
                    }
                ]
            });
        } else {
            setStep(2);
        }
    }, [step, closeDialog, showMessageDialog, closeMessageDialog]);

    useEffect(() => {
        const arePeriodsMonths = selectedPeriodsDesc.every((period: string) => MONTHS.some((month: string) => period.startsWith(month)));

        selectPeriodDesc({ areMonths: arePeriodsMonths, periods: selectedPeriodsDesc });

        if (selectedPeriodsDesc.length === 0) {
            setSepaDescription('[COGNOME] [NOME] STIPENDIO');
            setDefaultPeriodDesc('');
            setOverridePeriodDesc('');
        } else if (selectedPeriodsDesc.length === 1) {
            setSepaDescription('[COGNOME] [NOME] STIPENDIO ' + (arePeriodsMonths ? 'MESE' : 'PERIODO') + ' ' + (selectedPeriodsDescShortName[0].replaceAll('/', '.').replaceAll(' - ', '-') ?? '').toUpperCase());
            setDefaultPeriodDesc(selectedPeriodsDescShortName[0].replaceAll(' - ', '-').toUpperCase());
            setOverridePeriodDesc(selectedPeriodsDescShortName[0].replaceAll(' - ', '-').toUpperCase());
        } else {
            setSepaDescription('[COGNOME] [NOME] STIPENDIO ' + selectedPeriodsDescShortName.map((p: string) => p.replaceAll('/', '.').replaceAll(' - ', '-')).join(' - ').toUpperCase());
            setDefaultPeriodDesc(selectedPeriodsDescShortName.map((p: string) => p.replaceAll(' - ', '-')).join(' - ').toUpperCase());
            setOverridePeriodDesc(selectedPeriodsDescShortName.map((p: string) => p.replaceAll(' - ', '-')).join(' - ').toUpperCase());
        }
    }, [selectedPeriodsDesc, selectedPeriodsDescShortName])

    useEffect(() => {
        if (!open) return;

        setIsLoading(true);
        setData([]);
        setDataToSave([]);
        setSepaDescription('');
        setStep(1);
        setSelectedPeriods([]);

        api.request('/admin/trainer_payments/general/' + season + '/info').then((res: any) => {
            setData(res);
            setIsLoading(false);
        });
    }, [open, season]);

    const buttons: ButtonProps[] = [
        {
            children: (step < 3 || step === 7) ? 'Annulla' : 'No',
            color: 'primary',
            onClick: () => handleCloseDialog()
        },
        {
            children: step < 3 ? 'Prosegui' : (step < 7 ? 'Sì' : 'Conferma'),
            color: 'primary',
            onClick: () => nextStep(),
            disabled: isLoading || (step === 7 && simulationData.totalTrainers === 0)
        },
    ];

    return (
        <>
            <CustomDialogWrapper open={open} onClose={() => handleCloseDialog()} title={'Inserimento massivo compensi'} buttons={buttons} isLoading={isLoading} maxWidth={step === 2 ? 'xl' : (step === 1 ? 'md' : 'sm')} fullWidth={step !== 1} contentProps={step === 2 ? { style: { display: 'flex' } } : {}}>
                {step === 1 && (
                    <TableContainer style={{ marginTop: '0', marginBottom: '12px', width: '700px' }}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', padding: '4px', width: '1px', whiteSpace: 'nowrap', paddingRight: '16px' }}>Periodo di riferimento</TableCell>
                                    <TableCell style={{ padding: '8px 0' }}>
                                        {selectedPeriodsDesc.map((desc: any) => {
                                            return (
                                                <p style={{ margin: '1px 0' }}>{desc}</p>
                                            )
                                        })}
                                    </TableCell>
                                    <TableCell style={{ textAlign: 'right', padding: '8px 0', width: '1px', whiteSpace: 'nowrap' }}>
                                        <Button onClick={() => setIsSelectPeriodsDialogOpen(true)} color={'primary'} variant={'outlined'} style={{ margin: '0 auto', display: 'block' }} disabled={isLoading}>
                                            Seleziona
                                        </Button>
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', padding: '4px', width: '1px', whiteSpace: 'nowrap', paddingRight: '16px' }}>Causale bonifici</TableCell>
                                    <TableCell colSpan={2} style={{ padding: '12px 0 0px 0' }}>
                                        <CustomTextField
                                            size='small'
                                            label='Causale bonifici'
                                            variant='outlined'
                                            value={sepaDescription}
                                            keepMounted={true}
                                            onChange={(e) => {
                                                setSepaDescription(e.target.value);
                                            }}
                                            disabled={isLoading}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', padding: '4px', width: '1px', whiteSpace: 'nowrap', paddingRight: '16px' }}>Nome periodo<br /><span style={{ fontWeight: 'normal', fontSize: '0.85em', lineHeight: '16px', display: 'block', marginTop: '2px' }}>verrà riportato nell'email<br />e nel messaggio WhatsApp</span></TableCell>
                                    <TableCell colSpan={2} style={{ padding: '12px 0 0px 0' }}>
                                        <CustomTextField
                                            size='small'
                                            label='Periodo pagamento'
                                            variant='outlined'
                                            value={overridePeriodDesc}
                                            keepMounted={true}
                                            onChange={(e) => {
                                                setOverridePeriodDesc(e.target.value);
                                            }}
                                            disabled={isLoading}
                                        />
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                {step === 2 && (
                    <div style={{ flex: 1 }}>
                        <TableContainer style={{ marginTop: '0', marginBottom: '12px' }}>
                            <Table size='small'>
                                <TableHead>
                                    <TableRow>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Cognome</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Nome</TableCell>
                                        <TableCell style={{ width: '110px' }}>Importo</TableCell>
                                        <TableCell>Fattura</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Dati anagrafici</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Soglia 5k</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>Soglia 15k</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', }} colSpan={2}>Rimanenza</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', }} colSpan={2}>Sforamento</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', }} colSpan={2}>Netto</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', }}>Tasse coll.</TableCell>
                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', }}>Tasse E.S</TableCell>
                                        <TableCell style={{ minWidth: '350px' }}>Causale bonifico</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((trainer: any, idx: number) => {
                                        const toPay = parseFloat(trainer?.paymentStatus?.toPayPayouts ?? 0);
                                        const rimanenza5k = Math.max(5000 - parseFloat(trainer?.paidAmountCurrentFiscalYear ?? 0.0), 0);
                                        const overflow = Math.max(getDataForTrainer(trainer.trainer.id, 'paid') - rimanenza5k, 0.0);
                                        const net = Math.min(getDataForTrainer(trainer.trainer.id, 'paid'), rimanenza5k) + overflow * 0.865;

                                        const rimanenza15k = Math.max(15000 - parseFloat(trainer?.paidAmountCurrentFiscalYear ?? 0.0), 0);
                                        //const overflow15k = Math.max(getDataForTrainer(trainer.trainer.id, 'paid') - rimanenza15k, 0.0);

                                        const missingData = [];
                                        if (!trainer.personalDetails.name) missingData.push('nome');
                                        if (!trainer.personalDetails.surname) missingData.push('cognome');
                                        if (!trainer.personalDetails.taxCode) missingData.push('codice fiscale');
                                        if (!trainer.personalDetails.iban) missingData.push('IBAN');
                                        if (!trainer.personalDetails.residence) missingData.push('residenza');

                                        return (
                                            <TableRow>
                                                <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>{trainer.trainer.lastName}</TableCell>
                                                <TableCell style={{ width: '1px', whiteSpace: 'nowrap' }}>{trainer.trainer.firstName}</TableCell>
                                                <TableCell style={{ padding: '10px 6px 0px 6px', width: '110px' }} >
                                                    <CustomTextField
                                                        size='small'
                                                        label='Importo'
                                                        variant='outlined'
                                                        value={getDataForTrainer(trainer.trainer.id, 'paid')}
                                                        keepMounted={true}
                                                        onChange={(e) => {
                                                            setDataForTrainer(trainer.trainer.id, 'paid', e.target.value)
                                                        }}
                                                        onBlur={() => {
                                                            setDataForTrainer(trainer.trainer.id, 'paid', zeroIfNan(Math.min(Math.max(((typeof getDataForTrainer(trainer.trainer.id, 'paid') === 'string') ? parseFloat(getDataForTrainer(trainer.trainer.id, 'paid').replaceAll(',', '.')) : getDataForTrainer(trainer.trainer.id, 'paid')), 0), toPay)).toFixed(2))
                                                        }}
                                                        disabled={isLoading}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ padding: '10px 6px 0px 6px', width: '150px' }} >
                                                    <CustomTextField
                                                        size='small'
                                                        label='Numero fattura'
                                                        variant='outlined'
                                                        value={getDataForTrainer(trainer.trainer.id, 'invoiceNumber')}
                                                        keepMounted={true}
                                                        onChange={(e) => {
                                                            setDataForTrainer(trainer.trainer.id, 'invoiceNumber', e.target.value)
                                                        }}
                                                        disabled={isLoading}
                                                    />
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    {missingData.length > 0 && (
                                                        <Tooltip title={'Dati incompleti: ' + missingData.join(', ')} arrow>
                                                            <WarningIcon style={{ height: '32px', width: '32px', marginTop: '6px', color: '#ff9800' }} />
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    {(rimanenza5k - getDataForTrainer(trainer.trainer.id, 'paid')) < 0.001 && !(getDataForTrainer(trainer.trainer.id, 'invoiceNumber') ?? '').trim() && (
                                                        <Tooltip title={'Sforamento: €' + Math.abs(5000 - parseFloat(trainer?.paidAmountCurrentFiscalYear ?? 0.0) - getDataForTrainer(trainer.trainer.id, 'paid')).toFixed(2)} arrow>
                                                            <WarningIcon style={{ height: '32px', width: '32px', marginTop: '6px', color: '#f44336' }} />
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                                <TableCell style={{ textAlign: 'center' }}>
                                                    {(rimanenza15k - getDataForTrainer(trainer.trainer.id, 'paid')) < 0.001 && !(getDataForTrainer(trainer.trainer.id, 'invoiceNumber') ?? '').trim() && (
                                                        <Tooltip title={'Sforamento: €' + Math.abs(15000 - parseFloat(trainer?.paidAmountCurrentFiscalYear ?? 0.0) - getDataForTrainer(trainer.trainer.id, 'paid')).toFixed(2)} arrow>
                                                            <WarningIcon style={{ height: '32px', width: '32px', marginTop: '6px', color: '#f44336' }} />
                                                        </Tooltip>
                                                    )}
                                                </TableCell>
                                                {!(getDataForTrainer(trainer.trainer.id, 'invoiceNumber') ?? '').trim() ? (
                                                    <>
                                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', backgroundColor: '#e8f4fd', textAlign: 'left', padding: '0 6px', paddingLeft: '8px' }}>€</TableCell>
                                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', backgroundColor: '#e8f4fd', textAlign: 'left', paddingLeft: '4px' }}>{rimanenza5k.toFixed(2)}</TableCell>
                                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', backgroundColor: '#e8f4fd', textAlign: 'left', padding: '0 6px', paddingLeft: '8px' }}>€</TableCell>
                                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', backgroundColor: '#e8f4fd', textAlign: 'left', paddingLeft: '4px', fontWeight: overflow > 0.001 ? 'bold' : undefined }}>{overflow.toFixed(2)}</TableCell>
                                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', backgroundColor: '#e8f4fd', textAlign: 'left', padding: '0 6px', paddingLeft: '8px' }}>€</TableCell>
                                                        <TableCell style={{ width: '1px', whiteSpace: 'nowrap', backgroundColor: '#e8f4fd', textAlign: 'left', paddingLeft: '4px' }}>{net.toFixed(2)}</TableCell>
                                                    </>
                                                ) : (
                                                    <TableCell style={{ width: '1px', whiteSpace: 'nowrap', backgroundColor: '#e8f4fd' }} colSpan={6} />
                                                )}
                                                <TableCell style={{ padding: '10px 6px 0px 6px', width: '120px' }} >
                                                    {overflow > 0.001 && !(getDataForTrainer(trainer.trainer.id, 'invoiceNumber') ?? '').trim() && (
                                                        <CustomTextField
                                                            size='small'
                                                            style={{ marginRight: '6px' }}
                                                            label='Tasse coll.'
                                                            variant='outlined'
                                                            value={getDataForTrainer(trainer.trainer.id, 'trainerTaxes')}
                                                            keepMounted={true}
                                                            onChange={(e) => {
                                                                setDataForTrainer(trainer.trainer.id, 'trainerTaxes', e.target.value);
                                                            }}
                                                            onBlur={() => {
                                                                setDataForTrainer(trainer.trainer.id, 'trainerTaxes', zeroIfNan(Math.max(((typeof getDataForTrainer(trainer.trainer.id, 'trainerTaxes') === 'string') ? parseFloat(getDataForTrainer(trainer.trainer.id, 'trainerTaxes').replaceAll(',', '.')) : getDataForTrainer(trainer.trainer.id, 'trainerTaxes')), 0)).toFixed(2));
                                                            }}
                                                            error={parseFloat(getDataForTrainer(trainer.trainer.id, 'trainerTaxes')) < 0.001}
                                                            disabled={isLoading}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell style={{ padding: '10px 6px 0px 6px', width: '120px' }} >
                                                    {overflow > 0.001 && !(getDataForTrainer(trainer.trainer.id, 'invoiceNumber') ?? '').trim() && (
                                                        <CustomTextField
                                                            size='small'
                                                            style={{ marginRight: '6px' }}
                                                            label='Tasse E.S'
                                                            variant='outlined'
                                                            value={getDataForTrainer(trainer.trainer.id, 'esTaxes')}
                                                            keepMounted={true}
                                                            onChange={(e) => {
                                                                setDataForTrainer(trainer.trainer.id, 'esTaxes', e.target.value);
                                                            }}
                                                            onBlur={() => {
                                                                setDataForTrainer(trainer.trainer.id, 'esTaxes', zeroIfNan(Math.max(((typeof getDataForTrainer(trainer.trainer.id, 'esTaxes') === 'string') ? parseFloat(getDataForTrainer(trainer.trainer.id, 'esTaxes').replaceAll(',', '.')) : getDataForTrainer(trainer.trainer.id, 'trainerTaxes')), 0)).toFixed(2));
                                                            }}
                                                            error={parseFloat(getDataForTrainer(trainer.trainer.id, 'esTaxes')) < 0.001}
                                                            disabled={isLoading}
                                                        />
                                                    )}
                                                </TableCell>
                                                <TableCell style={{ padding: '10px 6px 0px 6px' }} >
                                                    <CustomTextField
                                                        size='small'
                                                        label='Causale bonifico'
                                                        variant='outlined'
                                                        value={getDataForTrainer(trainer.trainer.id, 'sepaDescription')}
                                                        keepMounted={true}
                                                        onChange={(e) => {
                                                            setDataForTrainer(trainer.trainer.id, 'sepaDescription', e.target.value)
                                                        }}
                                                        disabled={isLoading}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}

                {step === 3 && (
                    <>
                        <p style={{ marginTop: 0 }}>
                            Per i seguenti istruttori sono già stati registrati dei pagamenti con fattura per quest'anno, ma non è stato indicato il numero di fattura per questo pagamento.
                        </p>
                        <ul>
                            {errroData.map((trainer: any) => {
                                return <li>{trainer.lastName} {trainer.firstName}</li>
                            })}
                        </ul>
                        <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                            Vuoi proseguire comunque?
                        </p>
                    </>
                )}

                {step === 4 && (
                    <>
                        <p style={{ marginTop: 0 }}>
                            I dati anagrafici dei seguenti istruttori sono incompleti. Proseguendo, i pagamenti per questi istruttori verranno registrati nel gestionale, ma non saranno inseriti nel file CBI per la banca.
                        </p>
                        <ul>
                            {errroData.map((trainer: any) => {
                                return <li>{trainer.lastName} {trainer.firstName} ({(trainer.missingData ?? []).map((d: string) => missingPersonalDataMap[d as keyof typeof missingPersonalDataMap]).join(', ')})</li>
                            })}
                        </ul>
                        <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                            Vuoi proseguire comunque?
                        </p>
                    </>
                )}

                {step === 5 && (
                    <>
                        <p style={{ marginTop: 0 }}>
                            Per i seguenti istruttori è stata superata la soglia annuale di €5000, ma non sono stati compilati i campi "tasse collaboratore" e "tasse E.S". Proseguendo, i pagamenti per questi istruttori non saranno registrati.
                        </p>
                        <ul>
                            {errroData.map((trainer: any) => {
                                return <li>{trainer.lastName} {trainer.firstName}</li>
                            })}
                        </ul>
                        <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                            Vuoi proseguire comunque?
                        </p>
                    </>
                )}

                {step === 6 && (
                    <>
                        <p style={{ marginTop: 0 }}>
                            Per i seguenti istruttori è stata superata la soglia annuale di €15000. Questo è solo un avviso, proseguendo i pagamenti verranno comunque registrati.
                        </p>
                        <ul>
                            {errroData.map((trainer: any) => {
                                return <li>{trainer.lastName} {trainer.firstName}</li>
                            })}
                        </ul>
                        <p style={{ marginBottom: 0, fontWeight: 'bold' }}>
                            Vuoi proseguire comunque?
                        </p>
                    </>
                )}

                {step === 7 && (
                    <>
                        {simulationData.totalTrainers === 0 ? (
                            <p style={{ margin: 0, fontWeight: 0 }}>
                                Nessun pagamento da registrare!
                            </p>
                        ) : (
                            <>
                                <p style={{ marginTop: 0 }}>
                                    Stai per registratre <b>{simulationData.totalTrainers}</b> pagament{simulationData.totalTrainers === 1 ? 'o' : 'i'}, per un totale di <b>€{simulationData.totalAmount.toFixed(2)}</b>.
                                </p>
                                <p>
                                    Verrà generato e scaricato un file in formato CBI (XML)
                                    {simulationData.totalTrainers !== simulationData.cbiTrainers ? (
                                        <>{' '} contentente <b>{simulationData.cbiTrainers}</b> pagament{simulationData.cbiTrainers === 1 ? 'o' : 'i'}.</>
                                    ) : (
                                        <>{' '} contenente le disposizioni di pagamento.</>
                                    )}
                                </p>
                                <p>
                                    {simulationData.totalTrainers === 1 ? 'L\'istruttore verrà notificato' : 'Gli istruttori verranno notificati'} con un'email e un messaggio WhatsApp.
                                </p>
                                <p style={{ marginBottom: 0 }}>
                                    Vuoi confermare l'inserimento?
                                </p>
                            </>
                        )}

                    </>
                )}

            </CustomDialogWrapper >

            <SelectPeriodsDialog
                open={isSelectPeriodsDialogOpen}
                closeDialog={() => setIsSelectPeriodsDialogOpen(false)}
                periods={data[0]?.periods ?? []}
                selectedPeriods={sortedSelectedPeriods}
                selectPeriod={selectPeriod}
                ignoreAmount
            />
        </>
    )
}

export default BulkPaymentsDialog;