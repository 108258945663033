import Typography from "@material-ui/core/Typography";
import GenericObject from "../../../../../../typesAdditional/GenericObject"
import Divider from "@material-ui/core/Divider";
import { useWindowSize } from "react-use";
import GreenButton from "../../../../../../common/components/GreenButton/GreenButton";
import YellowButton from "../../../../../../common/components/YellowButton/YellowButton";
import { useSelector } from "react-redux";
import { ReduxState } from "../../../../../../redux/types";
import { useState, useCallback, SetStateAction, Dispatch } from "react";
import api from "../../../../../../common/utils/api";
import snackbar from "../../../../../../common/utils/snackbar";
import Button from "@material-ui/core/Button";
import { downloadBlob } from "download.js";

interface Props {
    selectedWeek?: GenericObject;
    refreshWeeks: () => void;
    setIsWeekNotesDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const Header = ({ selectedWeek, refreshWeeks, setIsWeekNotesDialogOpen }: Props) => {
    const { width } = useWindowSize();
    const isMobile = width <= 900;

    const [isLoading, setIsLoading] = useState(false);

    const userInfo = useSelector((state: ReduxState) => state.user);

    const canManageStaff = userInfo?.canManageStaff ?? false;

    const base64toBlob = useCallback((dataURI: string) => {
        // convert a base64 string to a blob object
        const byteString = atob(dataURI);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab]);
    }, []);

    const handleExport = () => {
        setIsLoading(true);
        api.request('/admin/summer_schedule/export_trainers_report', 'GET').then(res => {
            const blob = base64toBlob(res.content);
            downloadBlob(res.filename, blob);
        }).finally(() => {
            setIsLoading(false);
        });
    };

    const setWeekStatus = useCallback((weekId: string, status: string) => {
        setIsLoading(true);

        api.request('/admin/summer_schedule/weeks/' + weekId + '/status', 'POST', { status }).then(() => {
            refreshWeeks();
            snackbar.success('Stato modificato con successo!');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [refreshWeeks]);

    const setWeekVisibility = useCallback((weekId: string, visibility: boolean) => {
        setIsLoading(true);

        api.request('/admin/summer_schedule/weeks/' + weekId + '/visibility', 'POST', { visibility }).then(() => {
            refreshWeeks();
            snackbar.success('Visibilità modificato con successo!');
        }).finally(() => {
            setIsLoading(false);
        });
    }, [refreshWeeks]);

    return (
        <div>
            <div style={{ display: isMobile ? 'block' : 'flex' }}>
                <Typography variant='h2' style={{ fontSize: isMobile ? '3em' : '3.6em', textAlign: isMobile ? 'center' : 'left' }}>
                    Turni estate 2024
                </Typography>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '18px' }}>
                    <div style={{ flexGrow: 1 }} />
                    <Typography variant='h2' style={{ fontSize: '2em', marginBottom: '4px', textAlign: isMobile ? 'center' : 'left' }}>
                        settimana {selectedWeek?.shortName}
                    </Typography>
                </div>

                <div style={{ flex: 1, flexGrow: 1 }} />

                {canManageStaff && (
                    <>
                        {!isMobile && (
                            <Button disabled={isLoading} variant={'outlined'} color='primary' onClick={() => setIsWeekNotesDialogOpen(oldStatus => !oldStatus)} style={{ height: 'fit-content', margin: 'auto 16px', flexGrow: 0, marginTop: isMobile ? '10px' : 'auto', marginBottom: isMobile ? '10px' : 'auto', display: isMobile ? 'block' : undefined, }}>
                                Note
                            </Button>
                        )}

                        <Button disabled={isLoading} variant={'contained'} color='primary' onClick={() => handleExport()} style={{ height: 'fit-content', margin: 'auto', flexGrow: 0, marginTop: isMobile ? '10px' : 'auto', marginBottom: isMobile ? '10px' : 'auto', display: isMobile ? 'block' : undefined, }}>
                            Esporta report
                        </Button>

                        {selectedWeek?.visible ? (
                            <GreenButton disabled={isLoading} onClick={() => {
                                if (canManageStaff) {
                                    setWeekVisibility(selectedWeek?.id ?? '', false);
                                }
                            }} style={{ margin: isMobile ? '8px auto 0' : 'auto 16px', display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined, width: '180px', height: 'fit-content' }}>
                                Turni visibili
                            </GreenButton>
                        ) : (
                            <YellowButton disabled={isLoading} onClick={() => {
                                if (canManageStaff) {
                                    setWeekVisibility(selectedWeek?.id ?? '', true);
                                }
                            }} style={{ margin: isMobile ? '8px auto 0' : 'auto 16px', display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined, width: '180px', height: 'fit-content' }}>
                                Turni non visibili
                            </YellowButton>
                        )}
                    </>
                )}

                {(canManageStaff || selectedWeek?.visible) ? (
                    <>
                        {selectedWeek?.status === 'final' ? (
                            <GreenButton disabled={isLoading} noaction={!canManageStaff} onClick={() => {
                                if (canManageStaff) {
                                    setWeekStatus(selectedWeek?.id ?? '', 'draft');
                                }
                            }} style={{ margin: isMobile ? '8px auto 0' : undefined, display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined, width: '180px' }}>
                                Turni definitivi
                            </GreenButton>
                        ) : (
                            <YellowButton disabled={isLoading} noaction={!canManageStaff} onClick={() => {
                                if (canManageStaff) {
                                    setWeekStatus(selectedWeek?.id ?? '', 'final');
                                }
                            }} style={{ margin: isMobile ? '8px auto 0' : undefined, display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined, width: '180px' }}>
                                Bozza turni
                            </YellowButton>
                        )}
                    </>
                ) : (
                    <YellowButton disabled={isLoading} noaction style={{ margin: isMobile ? '8px auto 0' : 'auto 16px', display: isMobile ? 'block' : undefined, flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined, width: '220px', height: 'fit-content' }}>
                        Turni non disponibili
                    </YellowButton>
                )}
            </div>

            <Divider style={{ margin: '16px 0' }} />
        </div>
    )
}

export default Header;