import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import { ButtonProps } from '@material-ui/core/Button';
import { downloadBlob } from 'download.js';
import moment from 'moment';

import CustomDialogWrapper from '../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import api from '../../../../../common/utils/api';
import { ReduxState } from '../../../../../redux/types';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    courseId: string;
    locationId: string;
    discounts: any[];
    sessionType: string;
    categorySlug: string;
    singleSessionEvent?: boolean;
}

const ExportDialog = (props: Props) => {
    const [isLoading2, setIsLoading2] = useState(false);

    const userInfo = useSelector((state: ReduxState) => state.user);
    const isAdmin = !!userInfo.administrator && !!userInfo.canDownloadReports;

    const { isOpen, onClose, discounts, courseId, locationId, sessionType, categorySlug, singleSessionEvent } = props;

    const [type, setType] = useState('full');
    const [subtype, setSubtype] = useState('food');
    const [discountId, setDiscountId] = useState(0);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedType = (event.target as HTMLInputElement).value;
        setType(selectedType);

        if (selectedType === 'report' && sessionType === 'weekly_events') {
            setSubtype('global');
        }
    }, [sessionType]);

    const handleSubtypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSubtype((event.target as HTMLInputElement).value);
    };

    const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountId(parseInt((event.target as HTMLInputElement).value, 10));
    };

    useEffect(() => {
        if (type === 'weekly_discount' && !discounts.find(d => d.realId === discountId) && discounts.length > 0) {
            setDiscountId(discounts[0].realId);
        }
    }, [type, discountId, discounts]);

    const base64toBlob = useCallback((dataURI: string) => {
        // convert a base64 string to a blob object
        const byteString = atob(dataURI);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab]);
    }, []);

    const handleExport = () => {
        setIsLoading2(true);
        api.request('/admin/courses/' + courseId + '/locations/' + locationId + '/registrations_export', 'GET', { type, subtype, discount_id: discountId }).then(res => {
            const blob = base64toBlob(res.content);
            downloadBlob(res.filename, blob);
        }).finally(() => {
            setIsLoading2(false);
        });
    };

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        },
        {
            children: 'Esporta',
            color: 'primary',
            onClick: handleExport
        }
    ];

    return (
        <CustomDialogWrapper open={isOpen} onClose={onClose} title={'Esporta iscrizioni'} buttons={buttons} isLoading={isLoading2} maxWidth='md' fullWidth contentProps={{}} >
            <Typography variant='h6' noWrap>
                Tipologia esportazione
            </Typography>
            <FormControl component='fieldset'>
                <RadioGroup aria-label='gender' name='gender1' value={type} onChange={handleChange}>
                    <FormControlLabel value='full' control={<Radio color={'primary'} />} label='Elenco completo' />

                    {!singleSessionEvent && (
                        <>
                            {(locationId === '53071144-732e-41d7-9b00-ad1ea583c640' || locationId === 'aac8866a-bc42-4460-8e26-7abd0c44e5cd' || locationId === "2b1ab895-d9c7-40ea-83c1-94092ee30123") && (
                                <FormControlLabel value='full_dalmine_discounts' control={<Radio color={'primary'} />} label='Elenco completo sconti Dalmine' />
                            )}

                            <FormControlLabel
                                value='weekly'
                                control={<Radio color={'primary'} />}
                                label={'Elenchi ' + ((sessionType === 'single_event' || sessionType === 'weekly_events') ? 'giornalieri' : 'settimanali') + (categorySlug === 'summer_camp' ? ' saldo quote e presenze' : '')} />

                            <FormControlLabel value='weekly_ages' control={<Radio color={'primary'} />} label={'Elenchi ' + ((sessionType === 'single_event' || sessionType === 'weekly_events') ? 'giornalieri' : 'settimanali') + ' - bimbi divisi per anno di nascita'} />

                            {sessionType !== 'weekly_events' && (
                                <>
                                    <FormControlLabel value='weekly_food' control={<Radio color={'primary'} />} label={'Elenchi ' + ((sessionType === 'single_event' || sessionType === 'weekly_events') ? 'giornalieri' : 'settimanali') + ' - solo pasti'} />

                                    {discounts.filter(d => d.conditionType !== 'custom_single_registration' && d.conditionType !== 'voucher').length > 0 && (
                                        <FormControlLabel value='weekly_discount' control={<Radio color={'primary'} />} label={'Elenchi ' + ((sessionType === 'single_event' || sessionType === 'weekly_events') ? 'giornalieri' : 'settimanali') + ' - solo iscrizioni con sconto selezionato'} />
                                    )}

                                    <FormControlLabel value='special_diets' control={<Radio color={'primary'} />} label={'Diete speciali'} />

                                    <FormControlLabel value='unpaid_people_single_course' control={<Radio color={'primary'} />} label={(
                                        'Iscritti non paganti al ' + moment().format('DD/MM/YYYY')
                                    )} />

                                </>
                            )}
                        </>
                    )}

                    <FormControlLabel value='missing_cm_single_course' control={<Radio color={'primary'} />} label={(
                        'Certificati Medici Mancanti al ' + moment().format('DD/MM/YYYY')
                    )} />

                    <FormControlLabel value='no_photo_single_course' control={<Radio color={'primary'} />} label={(
                        'Iscritti NO FOTO'
                    )} />

                    {isAdmin && userInfo.canDownloadAdvancedReports && (
                        <>
                            <FormControlLabel value='shareholder_register' control={<Radio color={'primary'} />} label='Libro soci' />
                            <FormControlLabel value='membership' control={<Radio color={'primary'} />} label='Tesseramenti' />
                        </>
                    )}

                    {!singleSessionEvent && (
                        <>
                            {(isAdmin || sessionType !== 'weekly_events') && (
                                <FormControlLabel value='report' control={<Radio color={'primary'} />} label='Report riepilogativo' />
                            )}
                        </>
                    )}
                </RadioGroup>
            </FormControl>

            {type === 'weekly_discount' && (
                <>
                    <Typography variant='h6' noWrap style={{ marginTop: '16px', fontSize: '1.25em' }}>
                        Sconto
                    </Typography>
                    <FormControl component='fieldset'>
                        <RadioGroup name='discount' value={discountId} onChange={handleDiscountChange}>
                            {discounts.map((discount: any) => {
                                if (discount.conditionType === 'custom_single_registration' || discount.conditionType === 'voucher') {
                                    return null;
                                }

                                return (
                                    <FormControlLabel value={discount.realId} control={<Radio color={'primary'} />} label={discount.extendedNameDescription} />
                                );
                            })}
                        </RadioGroup>
                    </FormControl>
                </>
            )}

            {type === 'report' && (
                <>
                    <Typography variant='h6' noWrap style={{ marginTop: '16px', fontSize: '1.25em' }}>
                        Report da esportare
                    </Typography>
                    <FormControl component='fieldset'>
                        <RadioGroup name='discount' value={subtype} onChange={handleSubtypeChange}>
                            {sessionType !== 'weekly_events' ? (
                                <>
                                    <FormControlLabel value={'food'} control={<Radio color={'primary'} />} label={'Servizio pasti - camp selezionato'} />

                                    {isAdmin && (
                                        <FormControlLabel value={'food_global'} control={<Radio color={'primary'} />} label={'Servizio pasti - tutti i camp'} />
                                    )}

                                    <FormControlLabel value={'discount'} control={<Radio color={'primary'} />} label={'Sconti - camp selezionato'} />

                                    {isAdmin && (
                                        <>
                                            <FormControlLabel value={'discount_global'} control={<Radio color={'primary'} />} label={'Sconti - tutti i camp'} />
                                            <FormControlLabel value={'global'} control={<Radio color={'primary'} />} label={'Riepilogo iscrizioni tutti i camp'} />
                                            <FormControlLabel value={'global_people'} control={<Radio color={'primary'} />} label={'Riepilogo utenti tutti i camp'} />
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {isAdmin && (
                                        <>
                                            <FormControlLabel value={'global'} control={<Radio color={'primary'} />} label={'Riepilogo di tutti i corsi'} />
                                            <FormControlLabel value={'global_winter'} control={<Radio color={'primary'} />} label={'Riepilogo di tutti i corsi - diviso per giorni'} />
                                        </>
                                    )}
                                </>
                            )}

                            {isAdmin && (
                                <>
                                    <FormControlLabel value='unpaid_people' control={<Radio color={'primary'} />} label={(
                                        'Iscritti non paganti ' + (categorySlug === 'summer_camp' ? 'tutti i camp ' : '') + 'al ' + moment().format('DD/MM/YYYY')
                                    )} />

                                    <FormControlLabel value='missing_cm' control={<Radio color={'primary'} />} label={(
                                        'Certificati Medici Mancanti al ' + moment().format('DD/MM/YYYY')
                                    )} />

                                    <FormControlLabel value='no_photo_all_courses' control={<Radio color={'primary'} />} label={(
                                        'Iscritti NO FOTO - ' + (categorySlug === 'summer_camp' ? 'tutti i camp ' : 'tutti i corsi')
                                    )} />

                                </>
                            )}

                        </RadioGroup>
                    </FormControl>
                </>
            )}
        </CustomDialogWrapper>
    );
};

export default ExportDialog;
