import { useCallback, useEffect, useMemo, useState } from 'react';
import { useWindowSize } from 'react-use';

import { Badge, Card, CardContent, Divider, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import moment from 'moment';
import 'moment/locale/it';
import styled from 'styled-components';
import GreenButton from '../../../../common/components/GreenButton/GreenButton';
import useInternalLoader from '../../../../common/hooks/useInternalLoader/useExternalLoader';
import { useMessageDialog } from '../../../../common/hooks/useMessageDialog/useMessageDialog';
import api from '../../../../common/utils/api';
import snackbar from '../../../../common/utils/snackbar';
import WeekDetailsDialog from './components/WeekDetailsDialog/WeekDetailsDialog';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Alert from '@material-ui/lab/Alert';
import CustomTextField from '../../../../common/components/CustomTextField/CustomTextField';
import SettingsIcon from '@material-ui/icons/Settings';
import IconButton from '@material-ui/core/IconButton';
import UpdateRequestDialog from './components/UpdateRequestDialog/UpdateRequestDialog';

const StyledBadge = styled(Badge)`
    & .MuiBadge-colorSecondary {
        background-color: #ff9800;
    }
`

const SummerAvailabilityPage = () => {
    const setIsLoading = useInternalLoader();

    const { width } = useWindowSize();
    const isMobile = width <= 900;

    const [showMessageDialog, closeMessageDialog] = useMessageDialog();

    const [rawData, setRawData] = useState<any>({});
    const [data, setData] = useState<any[]>([]);
    const [isAlreadyFilled, setIsAlreadyFilled] = useState(false);

    const [isWeekDetailsDialogOpen, setIsWeekDetailsDialogOpen] = useState(false);
    const [editingWeek, setEditingWeek] = useState('');

    const [isUpdateRequestDialogVisible, setIsUpdateRequestDialogVisible] = useState(false);

    const areTherePendingChanges = useMemo(() => {
        return (rawData?.pendingChanges ?? []).reduce((prev: any[], curr: any) => {
            return [...prev, curr.extendedChanges];
        }, []).length > 0;
    }, [rawData]);

    const summerWeeks = useMemo((): string[] => {
        return (rawData?.weeks) ?? [];
    }, [rawData]);

    const refreshData = useCallback(() => {
        api.request('/admin/summer_availability/personal').then(res => {
            if (res.exists) {
                setIsAlreadyFilled(true);
            }

            setData(res.data);
            setRawData(res);
            setIsLoading(false);
        });
    }, [setIsLoading]);

    useEffect(() => {
        refreshData();
    }, [refreshData]);

    const getWeekAvailability = useCallback((week) => {
        const weekData = data.find(x => x.week === week);

        if (weekData) {
            return weekData.availability ?? '';
        } else {
            return '';
        }
    }, [data]);

    const setWeekAvailability = useCallback((week, availability) => {
        setData((d: any) => {
            const weekData = {
                ...(d.find((x: any) => x.week === week) ?? {}),
                week, availability,
                ...(availability === 'parziale' ? {} : { detailed: [] })
            };

            return [
                ...d.filter((x: any) => x.week !== week),
                weekData
            ];
        })
    }, [setData]);

    const getWeekNotes = useCallback((week) => {
        const weekData = data.find(x => x.week === week);

        if (weekData) {
            return weekData.notes ?? '';
        } else {
            return '';
        }
    }, [data]);

    const setWeekNotes = useCallback((week, notes) => {
        setData((d: any) => {
            const weekData = {
                ...(d.find((x: any) => x.week === week) ?? {}),
                week, notes
            };

            return [
                ...d.filter((x: any) => x.week !== week),
                weekData
            ];
        })
    }, [setData]);

    const getDetailedAvailability = useCallback((week, day, dayTime) => {
        const weekData = data.find(x => x.week === week);
        const detailedData = weekData?.detailed ?? [];
        const currentData = detailedData.find((x: any) => x.day === day && x.dayTime === dayTime);

        if (currentData) {
            return currentData.availability ?? '';
        } else {
            return '';
        }
    }, [data]);

    const setDetailedAvailability = useCallback((week, day, dayTime, availability) => {
        setData((d: any) => {
            const oldWeekData = d.find((x: any) => x.week === week);
            const newDetailed = [
                ...((oldWeekData?.detailed ?? []).filter((x: any) => x.day !== day || x.dayTime !== dayTime)),
                {
                    day,
                    dayTime,
                    availability
                }
            ]

            const weekData = {
                ...oldWeekData,
                detailed: newDetailed,
            };

            return [
                ...d.filter((x: any) => x.week !== week),
                weekData
            ];
        })
    }, [setData]);

    const validateForm = useCallback(() => {
        const errors1: string[] = [];
        const errors2: string[] = [];

        summerWeeks.forEach(week => {
            const startDate = moment(week);
            const endDate = moment(week).add(4, 'days');

            if (!data.find((x: any) => x.week === week) || !['sì', 'no', 'parziale'].includes(data.find((x: any) => x.week === week).availability)) {
                errors1.push(startDate.format('LL') + ' - ' + endDate.format('LL'));
            } else if (data.find((x: any) => x.week === week).availability === 'parziale') {
                const detailed = (data.find((x: any) => x.week === week)).detailed ?? [];

                const notComplete = [...Array(5).keys()].some(day => {
                    return [0, 1].some((dayTime) => {
                        return !detailed.find((y: any) => y.day === day && y.dayTime === dayTime);
                    });
                })

                if (notComplete) {
                    errors2.push(startDate.format('LL') + ' - ' + endDate.format('LL'))
                }
            }
        })

        if (errors1.length > 0 || errors2.length > 0) {
            showMessageDialog({
                title: 'Errore validazione dati',
                message: (
                    <>
                        {errors1.length > 0 && (
                            <>
                                <p style={{ marginTop: 0 }}>È necessario indicare la disponibilità per le seguenti settimane:</p>
                                <ul>
                                    {errors1.map(err => {
                                        return (
                                            <li>{err}</li>
                                        )
                                    })}
                                </ul>
                            </>
                        )}

                        {errors2.length > 0 && (
                            <>
                                <p style={{ marginTop: 0 }}>È necessario compilare la disponibilità per i singoli giorni delle seguenti settimane, premendo sull'icona dell'ingranaggio:</p>

                                <ul>
                                    {errors2.map(err => {
                                        return (
                                            <li>{err}</li>
                                        )
                                    })}
                                </ul>
                            </>
                        )}
                    </>
                )
            });

            return false;
        } else {
            return true;
        }

    }, [showMessageDialog, summerWeeks, data]);

    const validateAndSaveData = useCallback(() => {
        if (validateForm()) {
            showMessageDialog({
                title: 'Salva disponibilità',
                message: (
                    <>
                        <p style={{ margin: '0px' }}>
                            Sei sicuro di volere salvare la disponibilità? Le successive modifiche richiederanno l'approvazione di un amministratore.
                        </p>
                    </>
                ),
                actions: [
                    {
                        text: 'Annulla',
                        action: () => {
                            closeMessageDialog();
                        }
                    },
                    {
                        text: 'Conferma',
                        action: () => {
                            closeMessageDialog();

                            api.request('/admin/summer_availability', 'POST', { data }).then(res => {
                                snackbar.success('Dati salvati con successo!');
                                setIsAlreadyFilled(true);
                            });
                        }
                    }
                ]
            });
        }
    }, [data, showMessageDialog, closeMessageDialog, validateForm]);

    const validateAndSaveUpdatedData = useCallback(() => {
        if (validateForm()) {
            setIsUpdateRequestDialogVisible(true);
        }
    }, [validateForm]);

    const closeUpdateRequestDialog = useCallback((reloadData = false) => {
        setIsUpdateRequestDialogVisible(false);

        if (reloadData) {
            setIsLoading(true);
            refreshData();
        }
    }, [refreshData, setIsLoading]);

    return (
        <>
            <div>
                <div style={{ display: isMobile ? undefined : 'flex', textAlign: isMobile ? 'center' : undefined }}>
                    <Typography variant='h2' style={{ fontSize: '3.6em', flexGrow: 1 }}>
                        Disponibilità estate 2024
                    </Typography>

                    <GreenButton onClick={() => {
                        if (isAlreadyFilled) {
                            validateAndSaveUpdatedData();
                        } else {
                            validateAndSaveData();
                        }
                    }} style={{ flexGrow: 0, marginTop: isMobile ? '10px' : undefined, marginBottom: isMobile ? '10px' : undefined, height: 'fit-content' }}>
                        Salva disponibilità
                    </GreenButton>
                </div>

                <Divider style={{ margin: '8px 0 12px' }} />
            </div>

            <Alert severity='info' style={{ maxWidth: '1000px', margin: '4px auto 8px' }}>
                <AlertTitle>Istruzioni per la compilazione</AlertTitle>
                <p>
                    In questa sezione puoi compilare la tua disponibilità per l'<b>Estate 2024</b>.
                </p>

                <p>
                    Per ogni settimana imposta la tua disponibilità tra <i>sì</i>, <i>no</i> e <i>parziale</i>.<br />
                    Impostando <i>parziale</i> comparirà l'icona di un ingranaggio, cliccandoci si aprirà una schermata dove potrai selezionare la disponibilità per ogni mattino e pomeriggio della settimana.
                </p>

                <p>
                    Ricordati di salvare i dati con il pulsante verde. Le modifiche successive alla prima compilazione devono essere approvate da un amministratore.
                </p>

                {areTherePendingChanges && (
                    <p>
                        Le modifiche evidenziate in giallo sono in attesa di approvazione da parte di un amministratore.
                    </p>
                )}
            </Alert>

            {isMobile ? (
                <>
                    {summerWeeks.map((week: string, index: number) => {
                        const startDate = moment(week);
                        const endDate = moment(week).add(4, 'days');

                        const weekPendingChanges = rawData.pendingChanges.find((p: any) => p.week === week)?.extendedChanges ?? [];

                        return (
                            <>
                                <Card elevation={2} style={{ marginBottom: '18px' }}>
                                    <CardContent>
                                        <Typography variant='h5' component='h2' style={{ marginBottom: '18px', fontSize: '1.45em' }}>
                                            {startDate.format('LL')} - {endDate.format('LL')}
                                        </Typography>

                                        <div style={{ display: 'flex' }}>
                                            <CustomTextField
                                                label='Disponibilità'
                                                variant='outlined'
                                                value={getWeekAvailability(week)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWeekAvailability(week, e.target.value)}
                                                select
                                                size='small'
                                                style={{ marginBottom: '14px', backgroundColor: weekPendingChanges.length ? '#fff4e5' : '#ffffff', flexGrow: 1 }}
                                            >
                                                <MenuItem value={'sì'}>
                                                    sì
                                                </MenuItem>
                                                <MenuItem value={'no'}>
                                                    no
                                                </MenuItem>
                                                <MenuItem value={'parziale'}>
                                                    parziale
                                                </MenuItem>
                                            </CustomTextField>

                                            <div style={{ flexShrink: 1 }}>

                                                {getWeekAvailability(week) === 'parziale' && (
                                                    <IconButton style={{ padding: '7px', }} edge='end' onClick={() => {
                                                        setIsWeekDetailsDialogOpen(true);
                                                        setEditingWeek(week);
                                                    }}>
                                                        <SettingsIcon />
                                                    </IconButton>
                                                )}
                                            </div>
                                        </div>

                                        <CustomTextField
                                            label='Note aggiuntive'
                                            variant='outlined'
                                            size='small'
                                            style={{ marginBottom: '0', backgroundColor: '#ffffff' }}
                                            value={getWeekNotes(week)}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWeekNotes(week, e.target.value)}
                                            keepMounted
                                        />
                                    </CardContent>
                                </Card>
                            </>
                        )
                    })}
                </>
            ) : (
                <>
                    <TableContainer component={Paper} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Settimana</TableCell>
                                    <TableCell>Disponibilità</TableCell>
                                    <TableCell>Note aggiuntive</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {summerWeeks.map((week: string, index: number) => {
                                    const startDate = moment(week);
                                    const endDate = moment(week).add(4, 'days');

                                    const weekPendingChanges = rawData.pendingChanges.find((p: any) => p.week === week)?.extendedChanges ?? [];

                                    return (
                                        <TableRow style={{ backgroundColor: (index % 2 === 0) ? '#eff9f0' : undefined }}>
                                            <TableCell style={{ width: '35%' }}>{startDate.format('LL')} - {endDate.format('LL')}</TableCell>
                                            <TableCell style={{ width: '25%' }}>
                                                <div style={{ display: 'flex' }}>
                                                    <CustomTextField
                                                        label='Disponibilità'
                                                        variant='outlined'
                                                        value={getWeekAvailability(week)}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWeekAvailability(week, e.target.value)}
                                                        select
                                                        size='small'
                                                        style={{ marginBottom: '0', backgroundColor: weekPendingChanges.length ? '#fff4e5' : '#ffffff', flexGrow: 1 }}
                                                    >
                                                        <MenuItem value={'sì'}>
                                                            sì
                                                        </MenuItem>
                                                        <MenuItem value={'no'}>
                                                            no
                                                        </MenuItem>
                                                        <MenuItem value={'parziale'}>
                                                            parziale
                                                        </MenuItem>
                                                    </CustomTextField>

                                                    <div style={{ flexShrink: 1, width: '32px' }}>
                                                        {weekPendingChanges.length ? (
                                                            <>
                                                                {getWeekAvailability(week) === 'parziale' && (
                                                                    <StyledBadge color="secondary" variant="dot">
                                                                        <IconButton style={{ padding: '7px', }} edge='end' onClick={() => {
                                                                            setIsWeekDetailsDialogOpen(true);
                                                                            setEditingWeek(week);
                                                                        }}>
                                                                            <SettingsIcon />
                                                                        </IconButton>
                                                                    </StyledBadge>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <>
                                                                {getWeekAvailability(week) === 'parziale' && (
                                                                    <IconButton style={{ padding: '7px', }} edge='end' onClick={() => {
                                                                        setIsWeekDetailsDialogOpen(true);
                                                                        setEditingWeek(week);
                                                                    }}>
                                                                        <SettingsIcon />
                                                                    </IconButton>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>


                                            </TableCell>
                                            <TableCell style={{ width: '50%' }}>
                                                <CustomTextField
                                                    label='Note aggiuntive'
                                                    variant='outlined'
                                                    size='small'
                                                    style={{ marginBottom: '0', backgroundColor: '#ffffff' }}
                                                    value={getWeekNotes(week)}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWeekNotes(week, e.target.value)}
                                                    keepMounted
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}

            <GreenButton onClick={() => {
                if (isAlreadyFilled) {
                    validateAndSaveUpdatedData();
                } else {
                    validateAndSaveData();
                }
            }} style={{ margin: '16px auto 0', display: 'block' }}>
                Salva disponibilità
            </GreenButton>

            <WeekDetailsDialog isOpen={isWeekDetailsDialogOpen} onClose={() => setIsWeekDetailsDialogOpen(false)} week={editingWeek} getDetailedAvailability={getDetailedAvailability} setDetailedAvailability={setDetailedAvailability} isReadOnly={false} pendingChanges={rawData?.pendingChanges ?? []} />

            <UpdateRequestDialog open={isUpdateRequestDialogVisible} closeDialog={closeUpdateRequestDialog} data={data} />
        </>
    );
};

export default SummerAvailabilityPage;
