import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';
import './index.css';
import "@mormat/react-scheduler/dist/mormat_react_scheduler.css";

Sentry.init({
  dsn: 'https://bfebcabc920148cabbbce9f2f6945816@o120147.ingest.sentry.io/6340121',
  release: process.env.REACT_APP_GIT_SHA ?? '',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0,
  environment: process.env.REACT_APP_SENTRY_ENV
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
