import { ButtonProps } from '@material-ui/core/Button';

import CustomDialogWrapper from '../../../../../../common/components/CustomDialogWrapper/CustomDialogWrapper';
import moment from 'moment';
import 'moment/locale/it';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import { Card, CardContent, MenuItem, TableBody, Typography } from '@material-ui/core';
import CustomTextField from '../../../../../../common/components/CustomTextField/CustomTextField';
import { useWindowSize } from 'react-use';

interface Props {
    isOpen: boolean;
    onClose: () => void;
    week: string;
    getDetailedAvailability: (week: string, day: number, dayTime: number) => string;
    setDetailedAvailability: (week: string, day: number, dayTime: number, availability: string) => void;
    isReadOnly: boolean;
    pendingChanges: any[];
}

const WeekDetailsDialog = (props: Props) => {
    const { isOpen, onClose, week, getDetailedAvailability, setDetailedAvailability, isReadOnly, pendingChanges } = props;

    const { width } = useWindowSize();
    const isMobile = width <= 900;

    const buttons: ButtonProps[] = [
        {
            children: 'Chiudi',
            color: 'primary',
            onClick: () => onClose()
        }
    ];

    const startDate = moment(week);
    const endDate = moment(week).add(4, 'days');

    return (
        <>
            <CustomDialogWrapper open={isOpen} onClose={() => onClose()} title={'Settimana ' + startDate.format('D MMMM') + ' - ' + endDate.format('D MMMM')} buttons={buttons} maxWidth='md' fullScreen={isMobile} fullWidth >
                {isMobile ? (
                    <>
                        {[...Array(5).keys()].map((day) => {
                            return (
                                <Card elevation={2} style={{ marginBottom: '18px' }}>
                                    <CardContent>
                                        <Typography variant='h5' component='h2' style={{ marginBottom: '12px', fontSize: '1.45em' }}>
                                            {moment(week).add(day, 'days').format('dddd DD/MM/YYYY')}
                                        </Typography>

                                        {[0, 1].map((dayTime) => {
                                            const label = dayTime === 0 ? 'Mattino' : 'Pomeriggio';

                                            const pendingChangeExists = (pendingChanges.find((p: any) => p.week === week)?.extendedChanges ?? []).filter((p: any) => p.day === (day + 1) && p.dayTime === (dayTime ? 'afternoon' : 'morning')).length > 0;

                                            return (
                                                <CustomTextField
                                                    label={label}
                                                    variant='outlined'
                                                    value={getDetailedAvailability(week, day, dayTime)}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDetailedAvailability(week, day, dayTime, e.target.value)}
                                                    select
                                                    size='small'
                                                    style={{ width: '100%', backgroundColor: pendingChangeExists ? '#fff4e5' : '#ffffff', marginTop: '6px' }}
                                                    inputProps={{ readOnly: isReadOnly }}
                                                >
                                                    <MenuItem value={'sì'}>
                                                        sì
                                                    </MenuItem>
                                                    <MenuItem value={'no'}>
                                                        no
                                                    </MenuItem>
                                                    <MenuItem value={'forse'}>
                                                        forse
                                                    </MenuItem>
                                                </CustomTextField>
                                            )
                                        })}
                                    </CardContent>
                                </Card>
                            )
                        })}
                    </>
                ) : (
                    <TableContainer component={Paper} >
                        <Table>
                            <TableHead>
                                <TableRow>
                                    {[...Array(5).keys()].map((day) => {
                                        return (
                                            <TableCell style={{ width: '20%' }}>{moment(week).add(day, 'days').format('dddd DD/MM')}</TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {[0, 1].map((dayTime) => {
                                    const label = dayTime === 0 ? 'Mattino' : 'Pomeriggio';

                                    return (
                                        <TableRow>
                                            {[...Array(5).keys()].map((day) => {
                                                const pendingChangeExists = (pendingChanges.find((p: any) => p.week === week)?.extendedChanges ?? []).filter((p: any) => p.day === (day + 1) && p.dayTime === (dayTime ? 'afternoon' : 'morning')).length > 0;

                                                return (
                                                    <TableCell>
                                                        <CustomTextField
                                                            label={label}
                                                            variant='outlined'
                                                            value={getDetailedAvailability(week, day, dayTime)}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDetailedAvailability(week, day, dayTime, e.target.value)}
                                                            select
                                                            size='small'
                                                            style={{ marginBottom: '0', backgroundColor: pendingChangeExists ? '#fff4e5' : '#ffffff', flexGrow: 1 }}
                                                            inputProps={{ readOnly: isReadOnly }}
                                                        >
                                                            <MenuItem value={'sì'}>
                                                                sì
                                                            </MenuItem>
                                                            <MenuItem value={'no'}>
                                                                no
                                                            </MenuItem>
                                                            <MenuItem value={'forse'}>
                                                                forse
                                                            </MenuItem>
                                                        </CustomTextField>
                                                    </TableCell>
                                                )
                                            })}
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}


            </CustomDialogWrapper>
        </>
    );
};

export default WeekDetailsDialog;
