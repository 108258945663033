import React, { useEffect, useMemo, useRef } from 'react';

import { Editor, IAllProps } from '@tinymce/tinymce-react';

interface Props {
    setEditorRef: (ref: any) => void;
    useWhatsappStyle?: boolean;
    useMinimalStyle?: boolean;
    className?: string;
}

const TinyEditor = (props: Props) => {
    const { setEditorRef, useWhatsappStyle, useMinimalStyle, className } = props;

    const editorRef = useRef<any>(null);

    useEffect(() => {
        setEditorRef(editorRef);
    }, [editorRef, setEditorRef]);

    const initData = useMemo((): IAllProps['init'] => {
        if (useWhatsappStyle) {
            return {
                plugins: 'paste searchreplace autolink charmap lists wordcount help charmap quickbars emoticons textpattern',
                imagetools_cors_hosts: ['picsum.photos'],
                menubar: 'edit view insert tools',
                toolbar: 'undo redo | bold italic strikethrough | numlist bullist | charmap emoticons',
                toolbar_sticky: false,
                autosave_ask_before_unload: true,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                image_advtab: true,
                importcss_append: true,
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: 400,
                image_caption: true,
                // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                quickbars_insert_toolbar: '',
                quickbars_selection_toolbar: '',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: '',
                skin: 'oxide',
                content_css: 'default',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px } p { margin: 0 }',
                language: 'it',
                valid_elements: 'p,i,em,b,strong,s,del,ol,ul,li,br',
                text_patterns: [
                    { start: '_', end: '_', format: 'italic' },
                    { start: '*', end: '*', format: 'bold' },
                    { start: '~', end: '~', format: 'strikethrough' },
                    { start: '1. ', cmd: 'InsertOrderedList' },
                    { start: '* ', cmd: 'InsertUnorderedList' },
                    { start: '- ', cmd: 'InsertUnorderedList' },
                    { start: '• ', cmd: 'InsertUnorderedList' },
                ],
            }
        } else {
            return {
                plugins: 'paste importcss searchreplace autolink visualblocks visualchars image link table charmap hr pagebreak nonbreaking toc advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons code',
                imagetools_cors_hosts: ['picsum.photos'],
                menubar: useMinimalStyle ? '' : 'edit view insert format tools table help',
                toolbar: useMinimalStyle ? 'undo redo | bold italic underline strikethrough | numlist bullist | forecolor backcolor removeformat' : 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | image link',
                toolbar_sticky: false,
                autosave_ask_before_unload: true,
                autosave_interval: '30s',
                autosave_prefix: '{path}{query}-{id}-',
                autosave_restore_when_empty: false,
                autosave_retention: '2m',
                image_advtab: true,
                importcss_append: true,
                template_cdate_format: '[Date Created (CDATE): %m/%d/%Y : %H:%M:%S]',
                template_mdate_format: '[Date Modified (MDATE): %m/%d/%Y : %H:%M:%S]',
                height: useMinimalStyle ? 300 : 400,
                image_caption: true,
                // quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                quickbars_insert_toolbar: '',
                quickbars_selection_toolbar: '',
                noneditable_noneditable_class: 'mceNonEditable',
                toolbar_mode: 'sliding',
                contextmenu: 'link image imagetools table',
                skin: 'oxide',
                content_css: 'default',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                language: 'it',
                resize: useMinimalStyle ? false : undefined
            }
        }
    }, [useMinimalStyle, useWhatsappStyle])

    return (
        <div style={{ display: 'contents' }} className={className}>
            <Editor
                tinymceScriptSrc='/tinymce/tinymce.min.js'
                onInit={(evt, editor) => editorRef.current = editor}
                initialValue=''
                init={initData}
            />
        </div>
    );
};

export default TinyEditor;
